import styled from "styled-components";
import Img from "gatsby-image";

export const FullScreenBanner = styled(Img)`
  display: block;
  width: 100vw;
  height: 77.5vh;
  position: absolute !important;
  top: 15vh;
  left: 0;
  z-index: -1;
`;
