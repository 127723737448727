import React, { FC, Fragment } from "react";
import Layout from "../components/layout";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
//TODO fix error "typewriter-effect/index.d.ts' is not a module"
import Typewriter from "typewriter-effect";
import { H1, Paragraph } from "../components/ui/typography";
import { ButtonLink } from "../components/ui/form/button";
import { graphql, useStaticQuery } from "gatsby";
import { ContentfulWelcome, Query } from "../generated/graphql";
import { useTheme } from "../components/layout/themeContext";
import { Theme } from "../components/layout/themeContext/themes";
import { FullScreenBanner } from "../components/ui/fullScreenBanner";
import { FluidObject } from "gatsby-image";
import { Main } from "../components/ui/semanticElements/main";
import { Article } from "../components/ui/semanticElements/article";

const IndexPage: FC = () => {
  const data = useStaticQuery<Query>(graphql`
    query {
      allContentfulWelcome {
        nodes {
          seoPageTitle
          seoPageDescription
          title
          text
          bannerDarkMode {
            title
            description
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          bannerLightMode {
            title
            description
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          productsPresentationBefore
          productsList {
            productName
          }
          productsPresentationAfter
          callToActionText
          callToActionLink
        }
      }
    }
  `);
  const {
    seoPageTitle,
    seoPageDescription,
    title,
    text,
    bannerDarkMode,
    bannerLightMode,
    productsPresentationBefore,
    productsList,
    productsPresentationAfter,
    callToActionLink,
    callToActionText
  }: ContentfulWelcome = data.allContentfulWelcome.nodes[0];

  const typeWriter = (
    <Typewriter
      options={{
        strings: productsList?.map(prod => prod?.productName),
        autoStart: true,
        loop: true,
        delay: 25,
        cursor: ""
      }}
    />
  );

  const { theme } = useTheme();
  const banner = theme == Theme.LIGHT ? bannerLightMode : bannerDarkMode;
  const background = (
    <FullScreenBanner fluid={banner?.fluid as FluidObject} alt={banner?.description as string} />
  );

  return (
    <Fragment>
      <Layout pageTitle={seoPageTitle as string} pageDescription={seoPageDescription as string}>
        <Main verticalCenter oneThird>
          <H1>{title}</H1>
          <Article>
            <Paragraph>{text}</Paragraph>
            <Paragraph as={"div"}>
              {productsPresentationBefore}
              {typeWriter}
            </Paragraph>
            <Paragraph>{productsPresentationAfter}</Paragraph>
          </Article>
          <ButtonLink to={`/${callToActionLink}`}>{callToActionText}</ButtonLink>
        </Main>
      </Layout>
      {background}
    </Fragment>
  );
};

export default IndexPage;
